var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": `modal-${_vm.id}`
    }
  }, [_c('transition', {
    attrs: {
      "name": "fade",
      "appear": ""
    }
  }, [_vm.modalVisible ? _c('div', {
    staticClass: "modal-wrapper"
  }, [_vm.modalVisible ? _c('div', {
    staticClass: "modal-overlay",
    on: {
      "click": _vm.closeModal
    }
  }) : _vm._e(), _c('div', {
    staticClass: "modal max-h-9/10 mdmax:max-h-4/5",
    class: [_vm.type === 'full' ? 'p-0' : 'p-6', {
      'rounded-md': _vm.corner === 'rounded'
    }],
    style: {
      maxWidth: _vm.width
    },
    attrs: {
      "role": "dialog"
    }
  }, [_vm.closeIconVisible ? _c('div', {
    staticClass: "absolute cursor-pointer flex justify-center items-center z-10",
    class: [_vm.type === 'full' ? '-right-4 -top-4 bg-white w-8 h-8 rounded-full shadow-small text-yellow' : 'right-4 top-4'],
    on: {
      "click": _vm.closeModal
    }
  }, [_c('Close')], 1) : _vm._e(), _c('div', {
    staticClass: "modal-body"
  }, [_vm._t("modal-content")], 2)])]) : _vm._e()])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }